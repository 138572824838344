@import "../../variables/variables";

.about {
  margin-top: 100px;

  h2 {
    margin: 0 auto;
    font-family: $metal-font;
    color: white;
    width: 75%;
    text-align: center;
  }

  .info-wrapper {
    margin: 30px auto;
    text-align: center;
    width: 75%;
    font-family: $skia-font;
    font-size: 1.4rem;
    color: $grey-color;
  }

  #multilayer-text {
    color: white;
    font-family: $metal-font;
    font-size: 2.5rem;
    background-repeat: no-repeat;
    background-image: url("../../resourses/Flow_landing_assets/multiplayer_title_banner.png");
    background-size: 100%;
    padding: 20px 200px;
  }

  #projectLove-text {
    color: white;
    font-family: $metal-font;
    font-size: 2.5rem;
    background-repeat: no-repeat;
    background-image: url("../../resourses/Flow_landing_assets/project_title_banner.png");
    background-size: 100%;
    padding: 45px;
  }

  .multilayer-container {
    display: flex;
    margin-top: 40px;
    justify-content: space-evenly;
    text-align: center;
    color: white;
    font-size: 1.4rem;
    font-family: $skia-font;
    margin-bottom: 150px;

    div {
      width: 30%;
      img {
        width: 100%;
      }
    }

    div:first-child {
      width: 30%;
      align-self: center;
    }
    div:nth-child(2) {
      width: 20%;

      align-self: flex-end;
    }
    div:last-child {
      width: 40%;

      align-self: flex-start;
    }
  }

  .game-info-wrapper {
    margin-top: 0px;
    display: flex;
    justify-content: space-evenly;
    div {
      width: 16%;
      text-align: center;
      color: $grey-color;
      font-size: 1.3rem;

      h3 {
        color: white;
        font-size: 2rem;
        font-weight: normal;
        font-family: $metal-font;
        margin-bottom: 10px;
      }
    }

    div:nth-child(2) {
      margin-top: 150px;
    }

    div:nth-child(3) {
      margin-top: 250px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .about {
    .multilayer-container {
      display: block;
      div {
        margin-bottom: 15%;
        width: 100% !important;
      }
    }
    .game-info-wrapper {
      display: block;
      div {
        margin: 0 auto 15% !important;
        width: 50% !important;
      }
    }
    #multilayer-text,
    #projectLove-text {
      display: none;
    }
  }
}
