@import "../../variables/variables";

.whitelisted-address {
  .rodal-dialog {
    width: 550px!important;
    height: 200px!important;
    background: #161a1a;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 550px) {
      width: 100%!important;
      height: max-content!important;
    }
  }
}

.whitelisted-address-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  p:first-child {
    color: limegreen;
    margin-bottom: 20px;
  }

  p:nth-child(2) {
    margin-bottom: 10px;
  }

  p {
    font-size: 25px;

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }

    span {
      color: red;
    }
  }
}

.Toastify__toast-container--bottom-left {
  font-weight: 600;
}
