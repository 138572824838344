@import "../../variables/variables";


.vampires {
  text-align: center;
  margin-top: 100px;
  font-family: $metal-font;
  h2 {
    color: white;
    margin-bottom: 40px;
  }
  h3 {
    font-size: 3rem;
    font-weight: normal;
    color: $red-color;
  }
  p{
    width: 75%;
    margin: 0 auto;
    font-family: $skia-font;
    font-size: 1.4rem;
  }

  .image-wrapper {
    margin-top: 50px;
    width: 100%;
    margin-bottom: 100px;
  }

  .how-to-buy-text {
    font-size: 1.4rem;
    width: 60%;
  }

  .payment-icons {
    margin: 60px auto 90px;
    width: 40%;
    display: flex;
    justify-content: space-between;

    a {
      display: block;
      width: 30%;
    }

  }

  .button {
    span{
      padding: 30px 50px;
      min-width: 120px;
      min-height: 40px;
      color: white;
      font-size: 1.8rem;
      background-image: url('../../resourses/Flow_landing_assets/button-decor.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}

@media screen and (max-width: 1024px) {
  .vampires {
    margin-top: 20px;
    .button {
      span {
        font-size: 1.5rem;
      }}
  }

}