@import "../../variables/variables";

.free-virgin {
  text-align: center;
  margin-top: 200px;
  font-size: 1.4rem;
  font-family: $skia-font;
  h2 {
    font-family: $metal-font;
  }
  p {
    margin-bottom: 20px;
    text-align: start;
  }
  div {
    display: flex;
    justify-content: space-around;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .virgin-input {
        justify-content: space-evenly;

        input {
          color: white;
          width: 250px;
          margin-top: 5px;
          max-height: 35px;
          background: black;
          border: 1px solid $grey-color;
          border-radius: 5%;
        }
        span {
          padding: 10px 50px;
          min-width: 120px;
          min-height: 28px;
          color: white;
          font-size: 1.8rem;
          background-image: url("../../resourses/Flow_landing_assets/button-decor.png");
          background-size: 100%;
          background-repeat: no-repeat;
          font-family: $metal-font;
        }
      }
    }

    .image-wrapper {
      width: 20%;
    }
  }
}

@media screen and(max-width: 1024px) {
  .free-virgin {
    margin-bottom: 150px;
    div {
      display: block;
      .image-wrapper {
        width: 60%;
        margin: 0 auto;
      }
    }
    .virgin-input {
      input{
        height: 150px;
        margin-bottom: 25px;
      }
    }
  }
}
