@import "../../variables/variables";


.art {
  text-align: center;
  margin-top: 100px;
  h2 {
    font-family: $metal-font;
    margin-bottom: 30px;
  }
  p{
    margin: 0 auto;
    width: 75%;
    font-size: 1.4rem;
    font-family: $skia-font;
    margin-bottom: 30px;
  }
  img {
    width: auto !important;
  }
}
//.react-multi-carousel-item {
//  width: auto !important;
//}
//.image-item {
//  padding: 0 20px 0 20px;
//}