@import "../../variables/variables";

.footer {
  margin-top: 100px;
  font-family: $skia-font;
  font-size: 1.4rem;
  color: $grey-color;
  text-align: center;
  a {
    color: white;
  }

  h2 {
    color: $red-color;
    font-family: $metal-font;
  }

  .logos-wrapper {
    margin-top: 75px;
    display: flex;
    justify-content: space-evenly;
    text-align: center;

    div:nth-child(2) {
      align-self: center;
    }
  }

  .contacts {
    color: white;
    h3 {
      font-family: $metal-font;
      font-weight: normal;
      font-size: 3rem;
      color: white;
      margin-bottom: 20px;
    }
  }
  .contact-icons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 50px auto 70px;
    color: white;
    font-family: $metal-font;

    div {
      a {
        color: white;
        display: flex;
        align-items: center;
      }
      img {
        width: auto;
        margin-right: 15px;
      }
    }
  }

  .footer-nav {
    margin-bottom: 20px;
    a {
      color: white;
      margin-right: 15px;
    }
    a::after {
      margin-left: 15px;
      content: "|";
    }
    span:last-child {
      a::after {
        content: "";
      }
    }
  }
  .authors {
    width: 50%;
    margin: 0 auto;
    border-top: 1px solid $dark-grey;
    padding: 25px;
  }
}

@media screen and(max-width: 1024px) {
  .footer {
    margin-top: 0;
   .contact-icons{
     flex-direction: column;
     justify-content:space-between;
     div{
       width: 30%;
       margin-bottom: 15px;
       img{
         width: 100%;
       }
     }
   }
    .footer-nav{
      display: none;
    }
    .authors {
      width: 80%;
    }
  }
}
