@import "../../variables/variables";

body {
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;

  overflow-x: hidden;
}

h1 {
  font-size: 5.5rem;
  margin: 0;
  font-weight: normal;
}

h2 {
  font-weight: normal;
  font-size: 4rem;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
}

a {
  color: white;
  text-decoration: none;
}

.landing-invisible {
  .header-wrapper {
    display: none;
  }
  #tsparticles {
    background: black;
  }
}

.landing-wrapper {

  width: 100%;
  //background-size: contain;
  background-origin: padding-box;
  background-clip: border-box;
  background: black;

  //background-image: url('');
  //background-image: url('.');
}

// text align
.vertical-center {
  display: flex;
  align-items: center;
}


.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}


// colors
.red {
  color: $red-color !important;
}
.grey {
  color: $grey-color !important;
}

.dark-grey {
  color: $dark-grey !important;
}

.white-color {
  color: white !important;
}

.gold-color {
  color: gold !important;
}

.blue-color {
  color: rgb(0,191,255) !important;
}

.bronze-color {
  color: rgb(205, 127, 50) !important;
}

.bold {
  font-weight: bold;
}

a:hover {
  color: red !important;
}

.join-button a:hover {
  color: white !important;
}

.button-container .join-button:hover {
  transition: 0.1s ease-in-out;
  transform: scale(1.1);
  background-image: url("../../resourses/Flow_landing_assets/button-spec.png") !important;

}

.whitelist .info-container .info-wrapper .join-button:hover, .vampires .button span:hover{
  background-image: url("../../resourses/Flow_landing_assets/button-spec.png") !important;
  background-position: center;
  padding: 20px 80px;
}

.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}

.d-none {
  display: none;
}

@media screen and(max-width: 1660px) {
  h1 {
    font-size: 5rem;
  }
}


@media screen and(max-width: 1024px) {
  h1 {
    font-size: 3rem !important;
  }

  h2, h3 {
    font-size: 2rem !important;
  }

  .landing-wrapper {
    overflow: hidden;
    background: url("../../resourses/Flow_landing_assets/bgFLOW.jpg") top center no-repeat #000 !important;
    width: 100%;
    background-size: cover;
    background-origin: padding-box;
    background-clip: border-box;
  }
  .start-block {
    width: 100%;
  }


  .start-block .timer .intro,.start-block .timer .main {
    font-size: 3rem;
  }

  .packs .title {
    font-size: 3rem;
  }

  .whitelist .join-button{
    font-size: 1.4rem !important;
  }
  .whitelist > p{
    font-size: 2rem !important;
    width: 100% !important;
  }
  .whitelist .info-wrapper {
    width: 75% !important;
    margin: 0 auto;
  }

  .free-virgin {
    width: 85%;
    margin: 0 auto;
  }

  .vampires {
    .payment-icons {
      width: 80% !important;
    }
  }

  .footer {
    width: 90%;
    margin: 0 auto;
   .logos-wrapper {
    width: 50%;
    margin: 0 auto;
     flex-direction: column !important;
     div{
       margin-bottom: 20px;
       a{
         color: white;
       }
     }
  }}
  .whitelist .info-container .info-wrapper .join-button:hover, .vampires .button span:hover{
    padding: 10% !important;
  }
}

@media screen and (min-width: 1921px){
  .landing-wrapper {
    background: black !important;
  }
}




@media screen and (max-width: 350px){ // button res fixes
  .join-button, #blockto-button span {
    font-size: 1rem !important;
  }
}


::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
  border-radius: 1px;
  background-color: #CCCCCC;
}

::-webkit-scrollbar
{
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #B8000B;
}



