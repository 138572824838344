@import "../../variables/variables";

.lands {
  margin-top: 120px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: normal;
    color: white;
    font-family: $metal-font;
  }

  .info-wrapper {
    font-family: $skia-font;
    font-size: 1.4rem;
    margin: 0 auto;
    color: $grey-color;
    text-align: center;
    width: 75%;
  }

  .world-map {
    width: 65%;
    margin: 40px auto 50px;

    img {
    }
  }

  .land-block {
    display: flex;
    justify-content: space-around;
    font-size: 1.4rem;
    color: white;
    font-family: $skia-font;
    margin-bottom: 60px;

    img {
      display: block;
      width: 50%;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: none;

      li::before {
        content: "•";
        color: red;
        margin-right: 10px;
      }
    }

    p {
      text-align: center;
      margin-bottom: 50px;
      font-family: $metal-font;
      font-size: 3rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .lands {
    .land-block {
      flex-direction: column;
      //display: block;

      img {
        width: 100%;
      }
    }
    margin-bottom: 0;
    .explore, .own-earn {
      flex-direction: column-reverse;
    }
  }
}
