@import "../../variables/variables";

.land-description {
  text-align: center;
  margin-top: 100px;
  h2 {
    font-family: $metal-font;
    margin-bottom: 35px;
  }
  .description-text {
    width: 80%;
    margin: 0 auto 70px;
    font-family: $skia-font;
    font-size: 1.4rem;
    p {
      margin-bottom: 30px;
    }
  }

  .map-zones {
    display: flex;
    justify-content: center;
    .image-wrapper {
      width: 40%;
      margin-right: 50px;
      align-self: center;
    }
    .land-classes {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 50%;

      .single-land {
        background-size: contain;
        text-align: start;
        height: 100px;
        font-family: $skia-font;
        padding: 2% 5% 5% 3%;
        background-repeat: no-repeat;
        h3 {
          font-family: $metal-font;
          font-size: 1.5rem;
          margin-top: 0;
          margin-bottom: 8px;
        }

        .common-title {
          color: #CB5E1D
        }

        .rare-title {
          color: #71C3D4
        }
        .epic-title {
          color: #FFCF24
        }

        .legendary-title {
          color: #9D1DFF
        }

        .mythical-title {
          color: #D7031C
        }

        .single-land-description {
          width: 80%;
          font-size: 100%;
          color: white;
        }

        .farming-rate {
          font-size: 1.1rem;
        }
      }
      .single-land:first-child {
        background-image: url("../../resourses/Flow_landing_assets/lands_zones_BG_common.png");
      }
      .single-land:nth-child(2) {
        background-image: url("../../resourses/Flow_landing_assets/lands_zones_BG_rare.png");
      }
      .single-land:nth-child(3) {
        background-image: url("../../resourses/Flow_landing_assets/lands_zones_BG_epic.png");
      }
      .single-land:nth-child(4) {
        background-image: url("../../resourses/Flow_landing_assets/lands_zones_BG_legendary.png");
      }
      .single-land:nth-child(5) {
        background-image: url("../../resourses/Flow_landing_assets/lands_zones_BG_mythical.png");
      }
    }
  }
}


@media screen and (max-width: 1295px) {
  .land-description .map-zones .land-classes {
    width: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .map-zones {
    flex-direction: column;
    width: 80%;
    align-items: center;
    margin: 0 auto;
    .image-wrapper {
      margin: 0 auto !important;
      width: 100% !important;
    }
    .land-classes{
      width: 100% !important;
    }
    .single-land {
      width: 100%;
      h3 {
        margin: 0;
      }
      .single-land-description {
        width: 90% !important;
        font-size: 1rem !important;
      }
      margin: 13px auto !important;
      padding-bottom: 50px !important;
    }
  }
}


@media screen and (max-width: 652px) {
  .single-land {
    background: none !important;
  }
}
@media screen and (max-width: 518px) {
  .single-land-description {
    margin-bottom: 0 !important;
  }
}
