@import "../../variables/variables";

.reviews {
  text-align: center;
  margin-top: 100px;
  h2 {
    font-family: $metal-font;
  }
  > p {
    font-size: 1.4rem;
    font-family: $skia-font;
    margin-bottom: 100px;
    color: $grey-color;
  }

  .reviews-wrapper {
    background-image: url("../../resourses/Flow_landing_assets/papers_back.png");
    background-size: 100%;
    height: 80vh;
    background-origin: border-box;
    background-repeat: no-repeat;
    position: relative;
    font-family: $skia-font;

    .reviewer-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10%;
      font-family: $metal-font;
      font-size: 1.4rem;

      img {
        width: 20%;
        margin-right: 5%;
      }
    }

    > div {
      font-size: 1.3rem;
      font-family: $skia-font;
      position: absolute;
      width: 20%;
      z-index: 5;
    }

    div:first-child {
      top: 3%;
      left: 5%;
    }

    div:nth-child(2) {
      top: 20%;
      left: 28%;
    }
    div:nth-child(3) {
      top: 17%;
      left: 55%;
    }
    div:nth-child(4) {
      top: 25%;
      left: 77%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .reviews {p {
    font-size: 1.2rem;
  }
}}

@media screen and (max-width: 1331px) {
  .reviews{
  p {
    font-size: 1rem;
  }}
}

@media screen and (max-width: 1024px) {
  .reviews {
    display: none;
  }
}
