@import "../../variables/variables";

.partners {
  text-align: center;
  margin-top: 100px;
  font-size: 1.4rem;
  font-family: $skia-font;

  h2 {
    font-family: $metal-font;
    margin-bottom: 40px;
  }
  p {
    margin: 0 auto;
    width: 75%;
  }
  div {
    div {
      width: 80%;
      margin: 40px auto;
      display: flex;
      //flex-wrap: wrap;
      justify-content: space-evenly;
      div {
        //width: 30%;
        img {
          //height: initial;
          max-height: 5rem;
          width: auto;
        }
      }
    }
  }
}

@media screen and(max-width: 1024px) {
  .partners {
    div {
      div {
        display: block;
        div {
          img {
            width: auto;
          }
        }
        .dappradar-logo {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
