@import "../../variables/variables";

.alice-carousel__next-btn {
  text-align: start;
}
#art-carousel .alice-carousel img {
  width: 100% !important ;
}

.packs {
  padding-bottom: 5%;
  margin-top: 150px;
  text-align: center;
  background: black;
  //margin-top: 150px;

  .title {
    font-family: $metal-font;
    color: white;
    font-size: 4rem;
  }

  .details {
    color: $grey-color;
    font-size: 1.4rem;
    font-family: $skia-font;
  }

  .packs-wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;

    .single-pack {
      width: 33%;
      display: flex;
      flex-direction: column;
      > div {
        align-self: center;
        width: 100%;

        video {
          width: 100%;
        }
      }

      div:first-child {
        position: relative;

        .print {
          left: 50%;
          top: -10%;
          position: absolute;
          color: white;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../../resourses/Flow_landing_assets/scratch_banner.png");
          padding: 10px 30px;
          font-size: 2.5rem;
          font-family: $metal-font;
          z-index: 5;
          p {
            transform: translate(3px, -7px);
          }
        }
      }

      .pack-info {
        //margin-left: 2rem;
        font-size: 1.4rem;
        align-self: center;
        align-items: center;
        color: white;
        display: flex;
        flex-direction: column;
        font-family: $skia-font;

        p {
          margin-left: 15px;
          margin-bottom: 15px;
          text-align: start;
        }

        p:nth-child(3),
        p:nth-child(4) {
          color: $grey-color;
          font-size: 1.1rem;
        }

        p:first-child {
          font-size: 1.8rem;
          font-family: $metal-font;
        }

        .keys-block {
          display: flex;
          font-family: $skia-font;
          text-align: start;
          flex-direction: column;
          span {
            font-size: 1.2rem;
            color: $dark-grey;
            margin-left: 1rem;
          }
          div {
            display: flex;

            img {
              width: 20%;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .packs {
    height: auto;
    margin-bottom: 40px;
    margin-top: 0;
    padding-top: 15%;
    .packs-wrapper{
      display: block;

      .single-pack {
        display: flex;
        margin: 0 auto;
        width: 60%;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;

        > div {
          align-self: center;
        }

        .pack-info {
          p, div {
            width: 90%;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }
  }
}

//@media screen and (max-height: 680px) and (min-width: 1025px) {
//  .packs {
//    margin-top: 350px;
//  }
//}
